export function useInputMasks() {
  const textMaskOptions = {
    mask: 'A',
    tokens: {
      A: { pattern: /[А-я]/, multiple: true },
    },
  };

  const phoneMaskOptions = {
    mask: (value: string) => (value.startsWith('7') ? '+# ### ###-##-##' : '+7 ### ###-##-##'),
    preProcess: (value: string) => (value.startsWith('+') ? value.slice(1) : value),
    eager: true,
  };

  const emailMaskOptions = {
    mask: 'ABDCE',
    preProcess: (value: string) => value.toLowerCase(),
    tokens: {
      A: { pattern: /[A-z0-9.-]/, multiple: true },
      B: { pattern: /@/ },
      C: { pattern: /\./ },
      D: { pattern: /[A-z0-9]/, multiple: true },
      E: { pattern: /[A-z]/, multiple: true },
    },
  };

  return { textMaskOptions, phoneMaskOptions, emailMaskOptions };
}
